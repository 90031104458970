var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "thirdWebSite" },
    [
      _c("i-header", {
        attrs: { title: "云小蜜" },
        on: { "back-event": _vm.toBack },
      }),
      _c("iframe", {
        attrs: { width: "100%", src: _vm.webSiteUrl, frameborder: "0" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }