<template>
    <div class="thirdWebSite">
        <i-header title="云小蜜" @back-event="toBack"></i-header>
        <iframe width="100%" :src="webSiteUrl" frameborder="0"></iframe>
    </div>
</template>

<script>
export default {
    name: "ThirdWebSite",
    data() {
        return {
            webSiteUrl: this.$smartStorage.get('webappAlias')?.thirdUrl || ''
        }
    },
    created() {
    },
    methods: {
        toBack() {
            this.$smartStorage.remove('webappAlias')
            this.$router.push({
                path: '/application'
            })
        }
    }
}
</script>

<style lang="less" scoped>
.thirdWebSite {
    width: 100vw;
    height: 100%;
    overflow-y: unset;
    iframe {
        height: calc(100% - 0.44rem);
    }
}
</style>
